import * as React from 'react'

function SvgComponent({scale, ...props}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={353}
      height={215}
      className="logo-root"
      {...props}
    >
      <rect
        id="prefix__backgroundrect"
        width="100%"
        height="100%"
        x={0}
        y={0}
        fill="none"
        stroke="none"
      />
      <style>{'.prefix__st0{fill:#fff}'}</style>
      <g className="prefix__currentLayer">
        <title>{'Layer 1'}</title>
        <g id="prefix__svg_1">
          <path
            className="prefix__st0"
            d="M60.42 98.11l-.07 1.51-.05-1.52c-.21-.03-.41-.05-.62-.05-21.24 0-38.53-17.28-38.53-38.53s17.28-38.53 38.53-38.53c5.28 0 9.57-4.29 9.57-9.57s-4.29-9.57-9.57-9.57c-31.8 0-57.67 25.87-57.67 57.67 0 30.84 24.12 56.14 55.03 57.62.21.03.42.05.63.05 21.24 0 38.53 17.28 38.53 38.53 0 21.24-17.28 38.53-38.53 38.53s-38.53-17.28-38.53-38.53c0-5.28-4.29-9.57-9.57-9.57S0 150.44 0 155.72c0 31.8 25.87 57.67 57.66 57.67 31.8 0 57.67-25.87 57.67-57.67 0-30.85-24.12-56.15-54.91-57.61z"
            id="prefix__svg_2"
          />
          <path
            d="M193.76 110.75l-8.41-9.79c1.37-3.74 2.05-7.66 2.05-11.68 0-4.24-3.45-7.69-7.69-7.69-3.15 0-5.85 1.9-7.05 4.61l-14.15-16.47c15.92-3.05 27.78-17.01 27.78-33.69 0-18.92-15.39-34.3-34.31-34.3-4.24 0-7.69 3.45-7.69 7.69s3.45 7.69 7.69 7.69c10.44 0 18.93 8.49 18.93 18.93s-8.49 18.93-18.93 18.93c-.24 0-.47.02-.55.04-18.31.88-32.64 15.93-32.64 34.26 0 18.92 15.39 34.31 34.31 34.31 8.73 0 17.11-3.35 23.44-9.28l5.55 6.46c2.71 3.17 7.67 3.54 10.85.82 3.21-2.76 3.58-7.62.82-10.84zm-27.27-8.12c-3.53 3.53-8.4 5.59-13.41 5.59-10.44 0-18.93-8.49-18.93-18.93 0-6.05 2.92-11.72 7.75-15.26l24.59 28.6z"
            id="prefix__svg_3"
            fill="#90b0a1"
          />
          <path
            className="prefix__st0"
            d="M344.04 0c-4.91 0-8.91 3.89-8.91 8.67v147.96c0 .52.06 1.02.14 1.23-1.51 21.94-19.9 39.12-41.87 39.12-22.22 0-40.42-17.35-41.87-39.2V11.31c0-5.22-3.99-9.47-8.91-9.47-4.91 0-8.91 4.25-8.91 9.47v146.47c-1.46 21.85-19.66 39.2-41.88 39.2-21.97 0-40.37-17.18-41.89-38.85.09-.49.15-.99.15-1.5v-1.82c0-4.78-3.99-8.67-8.91-8.67-4.91 0-8.91 3.89-8.91 8.67v1.82c0 .59.06 1.19.16 1.59 1.72 31.56 27.8 56.29 59.38 56.29 20.69 0 39.97-10.88 50.78-28.51 10.81 17.63 30.1 28.51 50.78 28.51 31.58 0 57.66-24.72 59.36-56.12.12-.59.19-1.18.19-1.76V8.67c.03-4.78-3.97-8.67-8.88-8.67z"
            id="prefix__svg_4"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
